<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col sm="12" id="preview" class="mb-3"></b-col>
          </b-row>
          <div slot="header">
            <strong>{{ staticNames.name }}&nbsp;Event Category</strong>
            <small>Form</small>
          </div>
              <b-row>
              <b-col sm="4">
                <b-form-group>
                  <label for="Title">Event Name</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="staticBanner.ec_name"
                    id="Title"
                    placeholder="Name"
                  ></b-form-input>
                </b-form-group>
              </b-col>              
            </b-row>
           
            <b-row>
              <b-col sm="3">
                <b-button @click="SaveEvent()" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ staticNames.name }}
                </b-button>
              </b-col>
              <b-col sm="3">
                <b-button v-if="staticNames.name == 'Add'" @click="resetMovieBanner" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";
import { Switch as cSwitch } from "@coreui/vue";

export default {
  data() {
    return {
        staticBanner:{
            ec_name:'',
        },
      staticNames: {
        name: "Add",
      },
      event_id: null,
    };
  },
  components: {
    cSwitch,
  },
  computed: {},
  watch: {},
  beforeMount() {
///if update check route for id and if got id then call the getApi in beforeMount, getApi created in methods. Save value into v-model.
    const { event_id, event_name } = this.$route.query;
    if (event_id, event_name) {
      this.staticNames.name = "Update";
      this.staticBanner.ec_name = event_name
      this.event_id = event_id
    }
  },
  mounted: function () {},
  methods: {
      SaveEvent(){
          let payload={
              ec_name:this.staticBanner.ec_name
          }
        if(this.event_id != null) {
          MasterService.updateEventList(payload, this.event_id)
        .then((response) => {
          const { data } = response;
          console.log("response", response);
          if (data.Status) {
          } else {
            this.showMessageSnackbar(data.message);
            this.$router.push("/events/eventlist")
          }
        })
        .catch((error) => {
          console.log("Catch on Banner-getBannerByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. Kindly contact your administrator"
          );
        });
        }else{
        MasterService.addEventList(payload)
        .then((response) => {
          const { data } = response;
          console.log("response", response);
          this.showMessageSnackbar(
                data.message
                  ? data.message
                  : data.error
                  ? data.error.message
                    ? data.error.message
                    : data.error
                  : data
              );
            if(data.message) {
              setTimeout(()=> {
                this.$router.push("/events/eventlist")
              },3000)
            }
        })
        .catch((error) => {
          console.log("Catch on Banner-getBannerByID Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. Kindly contact your administrator"
          );
        });
        }
      },
    
    resetMovieBanner: function () {
      this.staticBanner.ec_name = ""
    },
    
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style></style>
